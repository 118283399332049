import React, { useEffect, useState } from 'react';
import { ListWrapper, TableCell } from 'components/Hospital/PaginationForms';
import { diagnosisListWidth } from 'constants/pagination';
import moment from 'moment';
import DiagnosisListPaginationListButton from 'assets/Icons/Arrow/ArrowLineRoundRight';
import { calculateAge } from 'functions/calculateAge';
import { formattedTime } from 'functions/calculateTime';
import { changeMobileForm } from 'functions/changeMobileForm';
import { useNavigate } from 'react-router-dom';
import { pagePaths } from 'constants/path';
import ProcessCheckBar from 'components/Hospital/ProcessCheckBar';
import GenderMan from 'assets/Icons/Gender/GenderMan';
import GenderWoman from 'assets/Icons/Gender/GenderWoman';
import { DiagnosisListpaginationTableProps } from 'models/Hospital/paginationTypes';
import { useLoginStore } from 'store/useLoginDataStore';
import { AccountLevel, DiagnosisStatus } from 'utils/statusCode';
import CustomDropdown from 'components/Admin/Dropdown/CustomDropdown';
import _, { last } from 'lodash';
import BasicButton from 'components/Buttons/BasicButton';
import { AddDiagnosisApiResponseData } from 'models/Hospital/hospitalApiTypes';
import { Select } from 'antd';

const DiagnosisListPaginationTable = ({
    currentData,
    counselData,
    handleCounsellerConfirmAlert: handleReservationAlert,
    handleCounselCompleteAlert,
    handleCounselData,
}: DiagnosisListpaginationTableProps) => {
    const { loginData } = useLoginStore();
    const navigate = useNavigate();
    const [isShowSelectButton, setIsShowSelectButton] = useState(false);

    const isCounselorAccount = loginData.level === AccountLevel.externalCounselor;

    const reservationDateButton = (diagnosisId: string, reservationList: string[]) => {
        const parseOptionList = _.map(reservationList, (item, index) => {
            const parseDateTime = moment(item, 'YYYYMMDDHHmm').format('YYYY년 MM월 DD일 HH:mm');
            return { value: item, label: parseDateTime };
        }).sort((a, b) => a.label.localeCompare(b.label));
        return (
            <div onClick={(e) => e.stopPropagation()}>
                <Select
                    defaultValue='상담 일자를 선택하세요.'
                    style={{ width: 198, height: 54 }}
                    onChange={(e) => {
                        handleCounselData!({ diagnosisId: diagnosisId, reservationDate: e });
                    }}
                    options={parseOptionList}
                />
            </div>
        );
    };

    useEffect(() => {
        setIsShowSelectButton(false); // 또는 다른 초기 상태 설정
    }, [currentData]);

    const selectButton = (item: Omit<AddDiagnosisApiResponseData, 'prescriptionMemo' | 'assessmentAge'>) => {
        const isSelectedDiagnosis = counselData?.diagnosisId === item._id;

        return (
            <BasicButton
                onClick={(e) => {
                    e.stopPropagation();
                    // if (_.isEmpty(selectedDate)) {
                    //     return;
                    // }
                    handleReservationAlert!(true);
                }}
                disabled={!isSelectedDiagnosis || _.isEmpty(counselData.reservationDate)}
                $width='80px'
                $height='80%'
                $type='outLine'
            >
                {'상담 확정'}
            </BasicButton>
        );
    };

    return (
        <>
            {currentData?.map((item, idx) => {
                const { birthDate } = calculateAge(item.patientIdentityNumber);
                return (
                    <ListWrapper key={idx} onClick={() => navigate(pagePaths.viewDetaildiagnosis, { state: item })}>
                        <TableCell $width={diagnosisListWidth[0]} $type='name'>
                            {item.patientName + ' '}
                            {item.patientGender === 'male' ? <GenderMan /> : <GenderWoman />}
                        </TableCell>
                        <TableCell $width={diagnosisListWidth[1]}>{moment(birthDate, 'YYYYMMDD').format('YYYY/MM/DD')}</TableCell>
                        <TableCell $width={diagnosisListWidth[2]} $type='gradeName'>
                            {item.gradeName}
                        </TableCell>
                        <TableCell $width={diagnosisListWidth[3]}>{changeMobileForm(item.patientMobile)}</TableCell>
                        <TableCell $width={diagnosisListWidth[4]}>
                            {isCounselorAccount && item.status === DiagnosisStatus.completed ? reservationDateButton(item._id, item.counselDateTimeList) : formattedTime(item.diagnosisDateTime)}
                        </TableCell>
                        <TableCell $width={diagnosisListWidth[5]}>{isCounselorAccount && item.status < DiagnosisStatus.counselReserved ? selectButton(item) : item.doctorName}</TableCell>
                        <TableCell
                            cellType='processBarCell'
                            $width={diagnosisListWidth[6]}
                            onClick={(event) => {
                                if (isCounselorAccount && item.status === DiagnosisStatus.counselReserved) {
                                    event.stopPropagation();
                                    handleCounselData!({ diagnosisId: item._id, reservationDate: item.diagnosisDateTime });
                                    handleCounselCompleteAlert!(item._id, true);
                                }
                            }}
                        >
                            <ProcessCheckBar diagnosisInfo={item} $height='40px' />
                        </TableCell>
                        <TableCell $width={diagnosisListWidth[7]}>
                            <DiagnosisListPaginationListButton />
                        </TableCell>
                    </ListWrapper>
                );
            })}
        </>
    );
};

export default DiagnosisListPaginationTable;
